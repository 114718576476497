import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import Modal from '../components/Modal'
// import SampleForm from '../components/Form'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ReactPlayer from 'react-player/youtube'

// Images
import quality from '../img/fast.png'
import honesty from '../img/versatile.png'
import expertise from '../img/non-destructive.png'
import logoIcon from '../img/logo-icon.svg'
import logoIcon2 from '../img/videometer-logo.png'
import logoIconWhite from '../img/logo-icon-white.svg'
import pp1 from '../img/pp1.png'
import pp2 from '../img/pp2.jpg'
import pp3 from '../img/pp3.png'
import team1 from '../img/pedro.png'
import team2 from '../img/emerson.png'
import team3 from '../img/taylor.png'
import fb from '../img/facebook.svg'
import twitter from '../img/twitter.svg'
import linkedin from '../img/linkedin.svg'
import multispectral from '../img/Skyway_Multispectral_Imaging.png'
import machinelearning from '../img/Skyway_Machine_Learning_Software.png'
import automation from '../img/Skyway_Automation.png'
// import logoWhite from '../img/logo-white.svg'
// import kiplinger from '../img/kiplinger.png'
// import wired from '../img/wired.png'
// import wsj from '../img/wsj.png'
import videoBg from '../../static/video/seed-analysis.mp4'

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Skyway Analytics" description={siteDescription} />

        <div className="container-fluid hero-section" id="home">
          <div>
            <div className="row">
              <div className="col-md-8 offset-md-2 brand-stamp text-center">
                <h1 className="text-white">
                  Upgrade your seed and feed quality
                  <br />
                </h1>
                <h5 className="mt-0 mb-5 text-white">
                  with automated spectral imaging analysis
                </h5>
                <Modal bsPrefix="btn-global" html="Test My Samples">
                  <iframe
                    className="border-0"
                    title="calendaly"
                    src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                    height="700px"
                    width="100%"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </Modal>
              </div>
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              background: '#0000007a',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
              objectFit: 'cover',
              zIndex: '1',
            }}
          />
          <video
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
              objectFit: 'cover',
            }}
          >
            <source src={videoBg} type="video/mp4" />
            <track src="" kind="captions" srclang="en" label="English"></track>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="container-fluid value-props text-center">
          <div>
            <div className="row">
              <div className="col-md-4 vp-sub-div-1">
                <div>
                  <img src={quality} alt="Quality" />
                  <h4 className="mt-3 mb-1">Fast</h4>
                  <p>Results in seconds</p>
                </div>
              </div>
              <div className="col-md-4 vp-sub-div-2">
                <div>
                  <img src={honesty} alt="Honesty" />
                  <h4 className="mt-3 mb-1">Flexible</h4>
                  <p>Measure what you care about</p>
                </div>
              </div>
              <div className="col-md-4 vp-sub-div-3">
                <div>
                  <img src={expertise} alt="Expertise" />
                  <h4 className="mt-3 mb-1">Non-destructive</h4>
                  <p>Keep valuable samples in tact</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid slogan-section" id="aboutus">
          <div className="container sub-container">
            <div className="row">
              <div className="col-md-8 offset-md-2 text-center">
                <h2 className="pb-0">
                  <img src={logoIcon} className="logo-icon" alt="Logo" />
                  About Us
                </h2>
                {/* <div className="vert-line mb-3"></div> */}
                <h5 className="font-italic mt-0">
                  We provide the technology, training and support our customers
                  need to harness the power of multispectral imaging in their
                  labs and facilities.
                </h5>
                <h5 className="font-italic mt-0">
                  Our team combines years of experience in seed research,
                  product design and industrial automation to serve today’s
                  agricultural operators.
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid svc-section text-left"
          id="applications"
        >
          <div className="container">
            <h2 className="pb-0 mt-0 text-center">
              <img src={logoIcon} className="logo-icon" alt="Logo" />
              Applications
            </h2>
            {/* <div className="vert-line mb-4"></div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="seed-sec">
                  <div className="p-3 svc-content">
                    <h4 className="mt-3 text-white">Seed Analytics</h4>
                    <div className="svc-paragraph text-white">
                      <p>
                        Traditional methods of seed quality assessment are
                        effective but often time-consuming, labor intensive,
                        destructive and require highly trained experts
                      </p>
                      <p>
                        Harness the power of automated imaging with a fast,
                        non-destructive and flexible seed evaluation system.
                      </p>
                      <p>
                        Improve the efficiency of your purity, germination,
                        vigor, disease and coating analysis processes with a
                        single instrument.
                      </p>
                      <br />
                    </div>
                    <Link
                      className="btn btn-primary btn-learn"
                      to="/seed-analytics"
                      title="Feed Analytics"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="feed-sec">
                  <div className="p-3 svc-content">
                    <h4 className="mt-3 text-white">Feed Analytics</h4>
                    <div className="svc-paragraph text-white">
                      <p>
                        Traditional methods of manual feed quality assessment
                        are effective – but often labor intensive, subjective or
                        slow.
                      </p>
                      <p>
                        Harness the power of automated imaging with a fast and
                        flexible feed quality system.
                      </p>
                      <p>
                        Reduce fines, disputes and over-paying for ingredients
                        with high-quality, traceable quality data.
                      </p>
                      <br />
                    </div>
                    <Link
                      className="btn btn-primary btn-learn"
                      to="/feed-analytics"
                      title="Feed Analytics"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid tech-section" id="technology">
          <div className="container">
            <h2 className="pb-0 text-center mt-5">
              <img src={logoIcon} className="logo-icon" alt="Logo" />
              The Technology
            </h2>
            <div className="row tech-row-2 align-items-center">
              <div className="col-md-6 tech-img-container-2 text-center">
                <img
                  className="img-fluid mb-3"
                  src={multispectral}
                  width={400}
                  alt="multispectral"
                />
              </div>
              <div className="col-md-6">
                <div className="tech-content">
                  <div className="num-header">
                    <span className="tech-num-1" />
                    <h4 className="mt-3"> Fast and Accurate</h4>
                  </div>
                  <p>
                    Save time by combining visible light, UV, and NIR analysis
                    in a single scan — to measure over 200 aspects of each
                    individual seed, grain, or kibble in seconds.
                  </p>
                </div>
              </div>
            </div>
            <div className="row tech-row-3 flex-column-reverse flex-md-row align-items-center">
              <div className="col-md-6">
                <div className="tech-content">
                  <div className="num-header">
                    <span className="tech-num-2" />
                    <h4 className="mt-3"> Traceable Results</h4>
                  </div>
                  <p>
                    Record and store objective results – analyzed the same way
                    every time to reduce fines, disputes and slow-downs
                  </p>
                </div>
              </div>
              <div className="col-md-6 tech-img-container-3 text-center">
                <img
                  className="img-fluid mb-3"
                  src={machinelearning}
                  width={400}
                  alt="machine learning"
                />
              </div>
            </div>
            <div className="row tech-row-4 align-items-center">
              <div className="col-md-6 tech-img-container-4">
                <img className="img-fluid" src={automation} alt="automation" />
              </div>
              <div className="col-md-6">
                <div className="tech-content">
                  <div className="num-header">
                    <span className="tech-num-3" />
                    <h4 className="mt-3"> Cost and Time Efficient</h4>
                  </div>
                  <p>
                    Reduce hiring and training costs by automating repetitive
                    manual inspections — so analysts are free to work on
                    higher-value projects
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Modal bsPrefix="btn-global" html="Start a Feasibility Test">
                <iframe
                  className="border-0"
                  title="calendaly"
                  src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </Modal>
            </div>
          </div>
        </div>
        <div className="container-fluid faq-section text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="text-white mt-0 mb-0">
                  <img src={logoIconWhite} className="logo-icon" alt="Logo" />
                  Have Questions?
                </h2>
                <h4 className="mt-0 text-white">Visit Our FAQ Page</h4>
              </div>
              <div className="col-md-6 pt-3">
                <a href="/faq" className="btn-global">
                  Click Here
                  <br />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid review-section text-center"
          id="testimonial"
        >
          <div className="container sub-container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h2 className="text-white pb-0">
                  <img
                    src={logoIcon}
                    className="logo-icon"
                    alt="Logo Review Section"
                  />
                  Testimonials
                </h2>
                {/* <div className="vert-line mb-3"></div> */}
                <MySwiper slidesperview={1}>
                  <div>
                    <p className="mb-5 text-white">
                      I would be very surprised that the multi spectral
                      technology would not claim a key spot in the near future
                      in every (seed) lab, worldwide. The impact of systems like
                      this, combined with the support from companies such as
                      Skyway Analytics, are empowering this technology
                      enormously.
                    </p>
                    <img
                      className="w-100 d-block mx-auto mb-3 review-img"
                      src={pp1}
                      alt="Client One"
                    />
                    <h5 className="text-white m-0">Johan Van Asbrouk</h5>
                    <h6 className="mb-3 position">
                      {' '}
                      Executive Chairman of Rhino Research and President of the
                      International Seed Academy.
                    </h6>
                  </div>
                  <div>
                    <p className="mb-5 text-white">
                      Using multispectral imaging that can see beyond what the
                      human eye can detect is a game changer for identifying
                      diseases and germination in seed, feed grain and food. In
                      my opinion, all of these companies will be utilizing this
                      technology in the near future.
                    </p>
                    <img
                      className="w-100 d-block mx-auto mb-2 review-img"
                      src={pp2}
                      alt="Client Two"
                    />
                    <h5 className="text-white m-0">Ed Eggers</h5>
                    <h6 className="mb-3 position">Founder of Sunfield Seeds</h6>
                  </div>
                  <div>
                    <p className="mb-5 text-white">
                      Multispectral imaging has demonstrated the ability to
                      detect and quantify characteristics of individual seeds
                      that are relevant to their quality and performance. I
                      expect it to be an essential tool in assessing and
                      upgrading seed lots.
                    </p>
                    <img
                      className="w-100 d-block mx-auto mb-2 review-img"
                      src={pp3}
                      alt="Client Three"
                    />
                    <h5 className="text-white m-0">Kent Bradford, PhD</h5>
                    <h6 className="mb-3 position">
                      Distinguished Professor Emeritus in the Department of
                      Plant Sciences at the University of California, Davis
                    </h6>
                  </div>
                </MySwiper>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid text-center team-section" id="team">
          <div className="container text-white">
            <h2 className="pb-0 mt-2">
              <img src={logoIcon} className="logo-icon" alt="Logo" />
              Our Team
            </h2>
            {/* <div className="vert-line mb-4"></div> */}
            <div className="row">
              <div className="col-md-4">
                <div>
                  <div className="team-container">
                    <img src={team1} alt="Pedro" />
                  </div>
                  <h4 className=" mt-3">Pedro</h4>
                  <div className="d-none social-media social-media-icons mt-3 mb-3">
                    <a href="/">
                      <img src={fb} alt="Facebook" />
                    </a>
                    <a href="/">
                      <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="/">
                      <img src={linkedin} alt="Linked" />
                    </a>
                  </div>
                  <div className="team-info">
                    <p className="text-justify text-dark">
                      Pedro is a native of Brazil and has worked at UC Davis as
                      a research associate in Dr. Kent Bradford's lab for the
                      past 10 years. In this position, he has worked closely
                      with seed technology providers on the development and
                      implementation of new tools for the seed industry and
                      specializes in the assessment of seed quality, vigor and
                      storage life.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <div className="team-container">
                    <img src={team3} alt="Taylor" />
                  </div>
                  <h4 className="mt-3">Taylor</h4>
                  <div className="d-none social-media social-media-icons mt-3 mb-3">
                    <a href="/">
                      <img src={fb} alt="Facebook" />
                    </a>
                    <a href="/">
                      <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="/">
                      <img src={linkedin} alt="Linked" />
                    </a>
                  </div>
                  <div className="team-info text-dark">
                    <p className="text-justify">
                      Taylor grew up spending time on his grandparents’ farm
                      and, after managing an industrial automation software
                      portfolio and starting a technology company, has made his
                      way back to agriculture. He has experience as an engineer
                      and an inextinguishable passion for making a
                      <br />
                      difference.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <div className="team-container">
                    <img src={team2} alt="Emerson" />
                  </div>
                  <h4 className="mt-3">Emerson</h4>
                  <div className="d-none social-media social-media-icons mt-3 mb-3">
                    <a href="/">
                      <img src={fb} alt="Facebook" />
                    </a>
                    <a href="/">
                      <img src={twitter} alt="Twitter" />
                    </a>
                    <a href="/">
                      <img src={linkedin} alt="Linked" />
                    </a>
                  </div>
                  <div className="team-info">
                    <p className="text-justify text-dark">
                      Emerson graduated from the University of Iowa in 2014 with
                      a degree in Health and Human Physiology. Following
                      graduation, he moved to Colorado where he has since been
                      the operations manager for two companies in the Natural
                      Food Industry. Emerson also manages Dry Chain America, a
                      drying technology provider for the agricultural industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <Modal bsPrefix="btn-global" html="Start a Feasibility Test">
                <iframe
                  className="border-0"
                  title="calendaly"
                  src="https://calendly.com/skywayanalytics/skyway-analytics-feasability-website-faq"
                  height="700px"
                  width="100%"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                />
              </Modal>
            </div>
          </div>
          <div className="container text-white mt-5 pt-5">
            <h2 className="pb-0 mt-2">
              <img
                src={logoIcon2}
                className="logo-icon"
                alt="Logo"
                style={{ marginTop: '-5px' }}
              />
              Our Partner
            </h2>
            <p className="text-dark mt-4">
              Videometer was founded in 1999 and for more than 20 years, it has
              been a leading company in the production of{' '}
              <a
                href="https://videometer.com/"
                target="_blank"
                rel="noreferer noopener"
              >
                spectral imaging technologies
              </a>
              . A one-in-its-kind, the Videometer Technology combines Spectral
              Imaging with elements of AI and Machine Learning and Multivariate
              Statistics. Our user-friendly instruments and unique software
              provide you with a high-caliber experience, enhancing the way you
              conduct quality control and product analysis.
            </p>
            {/* <div className="vert-line mb-4"></div> */}
            <div className="row"></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
